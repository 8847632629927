import { useRouter } from 'next/router'
import cookies from 'next-cookies'

import Layout from '../components/Layout'
import LoginBox from '../components/login/LoginBox'
import SEO from "../components/SEO"

import DomainHandler from "../lib/domainHandler"
import { frontendURL } from '../lib/ladder'
import UserManager from '../lib/userManager'
import { useEffect } from 'react'


export async function getServerSideProps(context) {
  DomainHandler.redirectToApp(context);
  const authToken = cookies(context).auth_token || null;

  return { props: { authToken }}
}

export default function Login({authToken}) {
  const router = useRouter();
  useEffect(() => { if(authToken) UserManager.routeHome(router) });
  
  if(authToken) return(<></>)

  return (
    <div>
      <SEO title="Login" path='/login'/>
      <Layout>
        <LoginBox/>
      </Layout>
    </div>
  )
}