import EmployerLoginButton from "./EmployerLoginButton"
import WorkerLoginButton from "./WorkerLoginButton"
import styles from "./ProfileSelector.module.css"
import LittleBox from "../LittleBox"

export default function ProfileSelector({employerUrl, workerUrl}) {
  return (
    <>
      <h1 className={styles["profile-selector-title"]}>Select Account Type</h1>
      <div className="row justify-content-center">
        <EmployerLoginButton urlOverride={employerUrl}/>
        <WorkerLoginButton urlOverride={workerUrl}/>
      </div>
    </>
  )
}