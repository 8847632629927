import styles from "./LittleBox.module.css"

export default function LittleBox({children}){
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-5 col-md-6">
          <div className={styles["box"]}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}