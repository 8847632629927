import React, { useState, useEffect } from "react";
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'

import InputMask from "react-input-mask";
import ReactLoading from "react-loading";

import ToastManager from "../../lib/toastManager"
import UserManager from "../../lib/userManager"

import { UserAPI, frontendURL} from '../../lib/ladder'
import { cleanNumber, cleanAuthCode } from '../../lib/inputUtils'

import ProfileSelector from "./ProfileSelector";

import styles from './LoginBox.module.css'
import LittleBox from "../LittleBox";

export default function LoginBox() {

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showEmployerLogin, setShowEmployerLogin] = useState(false);
  const [showWorkerLogin, setShowWorkerLogin] = useState(false);
  const [showLoginSelector, setShowLoginSelector] = useState(true);
  const [showAuthCodeInput, setShowAuthCodeInput] = useState(false)
  const [authCode, setAuthCode] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const [showProfileLoading, setShowProfileLoading] = useState(false);
  
  const router = useRouter();

  useEffect(() => ToastManager.previewToast(), [])

  // useEffect(() => {
  //   if(UserManager.signedIn()) UserManager.routeHome(router);
  // }, [])

  useEffect(() => {
    if(router.query.type === "applicant") {
      setShowWorkerLogin(true);
      setShowLoginSelector(false);
    } else if(router.query.type === "employer") {
      setShowEmployerLogin(true);
      setShowLoginSelector(false);
    }
  }, [router.query.type])

  useEffect(() => {
    if(router.query.loading) {
      setShowProfileLoading(true)

      setShowLoginSelector(false)
      setShowWorkerLogin(false)
      setShowAuthCodeInput(false)
      setShowEmployerLogin(false)

      setTimeout(() => {
        setShowProfileLoading(false);
        setShowWorkerLogin(true)
        setPhoneNumber(router.query.number ? router.query.number.substring(2).replace(/\s/g, '') : "")

        ToastManager.successfullAccountCreation()
      }, 3000);
    }
  }, [router.query.loading])

  const validEmployerLogin = () => {
    if(!email || !password || email === "" || password === "") {
      return false;
    } else {
      return true;
    }
  }
 
  async function handleEmployerLogin(e) {
    e.preventDefault();
    if(!validEmployerLogin()) return;    
    setSubmitDisabled(true);

    const login = await UserAPI.login(email, password) 

    if(login.success) {
      UserManager.setAuthToken(login.auth_token);
      // ToastManager.successfullLogin();
      UserManager.routeHome(router);
    } else {
      ToastManager.errorToast(login.errors.join(", "))
    }
    setSubmitDisabled(false);
  }

  async function handleWorkerLogin(e) {
    e.preventDefault();
    if(!phoneNumber) return;    
    setSubmitDisabled(true);

    const sendAuthCode = await UserAPI.sendAuthCode(cleanNumber(phoneNumber)) 

    if(sendAuthCode.success) {
      setShowAuthCodeInput(true)
    } else {
      ToastManager.errorToast(sendAuthCode.errors.join(", "))
    }

    setSubmitDisabled(false)
  }

  async function handleWorkerAuthCode(e) {
    e.preventDefault();
    if(!authCode) return;    
    setSubmitDisabled(true);

    const res = await UserAPI.verifyAuthCode(cleanNumber(phoneNumber), cleanAuthCode(authCode)) 

    if(res.success) {
      UserManager.setAuthToken(res.auth_token);
      // ToastManager.successfullLogin();
      UserManager.routeHome(router);
    } else {
      ToastManager.errorToast(res.errors.join(", "))
    }

    setSubmitDisabled(false);
  }

  const handleLoginSelection = (selection) => {
    if(selection === "employer") {
      setShowEmployerLogin(true);
      setShowWorkerLogin(false);
      setShowLoginSelector(false);
    } else if(selection === "worker") {
      setShowWorkerLogin(true);
      setShowLoginSelector(false);
      setShowEmployerLogin(false);
    }

    router.push("/login", {shallow: true})
    setShowAuthCodeInput(false);
    setShowProfileLoading(false)
  }

  const buildProfileLoading = () => {
    if(!showProfileLoading) return;

    return(
      <div className={styles["profile-loader-container"]}>
        <p>Building profile</p>
        <img src="/building-profile-v3.svg"/>
      </div>
    )
  }

  const buildLoginSelector = () => {
    if(!showLoginSelector) return;

    return(
      <ProfileSelector/>
    )
  }

  const buildEmployerLogin = () => {
    if(!showEmployerLogin) return;

    return(
      <>
        <h1 className={styles["box-title"]}>Contractor Login</h1>
        <form className={styles["form-margin"]} onSubmit={handleEmployerLogin}>
          <div className="form-group">
            <input type="email" className="form-control" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required></input>
          </div>
          <div className="form-group">
            <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} required></input>
          </div>
          <button type="submit" className={styles["box-extra"] + " " + "btn btn-primary btn-block "} disabled={submitDisabled}>Log in</button>
          <a href={frontendURL() + "/registration/employer"} style={{marginTop: 10, display: "block", fontSize: "1rem", textAlign: "center"}} className="cleanest-link">Don't have an account? Create one here.</a>
          <a href={frontendURL() + "/forgot-password"} style={{marginTop: 10, display: "block", fontSize: "1rem", textAlign: "center"}} className="cleanest-link">Fogot password? Click here.</a>
        </form>
      </>
    )
  }

  const buildWorkerLoginForm = () => {
    if(showAuthCodeInput) {
      return(
        <form className={styles["form-margin"]} onSubmit={handleWorkerAuthCode}>
          <div className="form-group">
            <p className={styles["auth-code-info"]}>We just texted you a verification code. Enter it below to finish your login.</p>
            <InputMask className="form-control auth-code-input" placeholder="_ _ _ _" style={{marginBottom: 30}} mask="9 9 9 9" onChange={(e) => setAuthCode(e.target.value)} required></InputMask>
            <a href="javascript:void(0)" style={{marginTop: 10, fontSize: "1rem"}} className={"cleanest-link " + styles["auth-sub-info"]} onClick={() => handleLoginSelection("worker")}>Can't find your authcode? Click here to try again.</a>
          </div>
          <button type="submit" className={styles["box-extra"] + " " + "btn btn-primary btn-block "} style={{marginTop: 15}} disabled={submitDisabled}>Continue</button>
        </form>
      )
    } else {
      return(
        <form className={styles["form-margin"]} onSubmit={handleWorkerLogin}>
          <div className="form-group">
            <InputMask className="form-control" placeholder="Enter your phone number" style={{marginBottom: 10}} mask="(999) 999-9999" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} required></InputMask>
          </div>
          <button type="submit" className={styles["box-extra"] + " " + "btn btn-primary btn-block "} style={{marginTop: 15}} disabled={submitDisabled}>Continue</button>
          <a href="https://www.meetladder.com/jobs" style={{marginTop: 10, display: "block", fontSize: "1rem", textAlign: "center"}} className="cleanest-link">Haven't applied on Ladder before?<br/>Check out our job board here.</a>
        </form>
      )
    }
  }

  const buildWorkerLogin = () => {
    if(!showWorkerLogin) return;
    
    return(
      <>
        <h1 className={styles["box-title"]}>Worker Login</h1>
        {buildWorkerLoginForm()}
      </>
    )
  }

  return(
    <LittleBox>
      {buildLoginSelector()}
      {buildEmployerLogin()}
      {buildWorkerLogin()}
      {buildProfileLoading()}
    </LittleBox>
  )
}
